/*!

=========================================================
* Argon Design System React - v1.1.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

class XuanyutangGamesFooter extends React.Component {
  render() {
    return (
      <>
        <footer className=" footer">
          <Container>
            <Row className=" row-grid align-items-center mb-5">
              <Col lg="6">
                <h3 className=" text-primary font-weight-light mb-2">
                  感谢您使用绚予堂在线家庭视训工具!
                </h3>
                <h4 className=" mb-0 font-weight-light">
                  有任何疑问或建议，请联系我们。
                </h4>
              </Col>
            </Row>
            <hr />
            <Row className=" align-items-center justify-content-md-between">
              <Col md="6">
                <div className=" copyright">
                  Copyright © {new Date().getFullYear()}{" "}
                  XuanYuTang
                </div>
              </Col>
              <Col md="6">
                <Nav className=" nav-footer justify-content-end">
                  <NavItem>
                    <NavLink
                      href="https://beian.mps.gov.cn/#/query/webSearch?code=11010802044734"
                      target="_blank"
                    >
                      <img alt="beian" style={{marginLeft: '10px', width: '16px'}} src={require("assets/img/beian.png")}/>
                      {'  '}京公网安备11010802044734
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="https://beian.miit.gov.cn/"
                      target="_blank"
                    >
                      <img alt="beian" style={{marginLeft: '10px', width: '16px'}} src={require("assets/img/beian2.png")}/>
                      {'  '}京ICP备2024079324号-1
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default XuanyutangGamesFooter;
