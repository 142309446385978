/*!

=========================================================
* Argon Design System React - v1.1.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components

// core components

// index page sections
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row
} from "reactstrap";
import {Link} from "react-router-dom";
import XuanyutangGamesFooter from "../components/Footers/XuanyutangGamesFooter";
import XuanyutangGamesNavbar from "../components/Navbars/XuanyutangGamesNavbar";

class Index extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <XuanyutangGamesNavbar/>
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section section-lg section-shaped pb-250">
              <div className="shape shape-style-1 shape-default">
                <span/>
                <span/>
                <span/>
                <span/>
                <span/>
                <span/>
                <span/>
                <span/>
                <span/>
              </div>
              <Container className="py-lg-md d-flex">
                <div className="col px-0">
                  <Row>
                    <Col lg="6">
                      <h1 className="display-3 text-white">
                        绚予堂家庭视训工具{" "}
                        <span>让您在家也能进行视觉训练</span>
                      </h1>
                      <p className="lead text-white">
                        绚予堂，在视光行业独立深耕十载有余，
                        致力于为优质会员朋友们提供全生命周期的视光管理服务，
                        为您与家人的眼健康与视光保驾护航。
                      </p>
                    </Col>
                  </Row>
                </div>
              </Container>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
            {/* 1st Hero Variation */}
          </div>
          <section className="section section-lg pt-lg-0 mt--200">
            <Container>
              <Row className="justify-content-center">
                <Col lg="12">
                  <Row className="row-grid">
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                            <i className="fa fa-th"/>
                          </div>
                          <h6 className="text-primary text-uppercase">
                            舒尔特方格
                          </h6>
                          <p className="description mt-3">
                            舒尔特方格（Schulte Grid）是一种心理学上的训练工具，
                            最早由德国精神病学家舒尔特（Walter Schulte）提出。
                            它主要用于提高注意力和视知觉能力，
                            尤其是在速度和准确度方面。
                          </p>
                          <div>
                            <Badge color="primary" pill className="mr-1">
                              提高注意力
                            </Badge>
                            <Badge color="primary" pill className="mr-1">
                              提升视觉搜索能力
                            </Badge>
                            <Badge color="primary" pill className="mr-1">
                              锻炼瞬时记忆
                            </Badge>
                            <Badge color="primary" pill className="mr-1">
                              加强视野范围
                            </Badge>
                          </div>
                          <Button
                            className="mt-4"
                            color="primary"
                            href="#pablo"
                            to="/schulte-grid" tag={Link}
                          >
                            进入训练
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                    {/*<Col lg="4">*/}
                    {/*  <Card className="card-lift--hover shadow border-0">*/}
                    {/*    <CardBody className="py-5">*/}
                    {/*      <div className="icon icon-shape icon-shape-success rounded-circle mb-4">*/}
                    {/*        <i className="ni ni-istanbul"/>*/}
                    {/*      </div>*/}
                    {/*      <h6 className="text-success text-uppercase">*/}
                    {/*        Build Something*/}
                    {/*      </h6>*/}
                    {/*      <p className="description mt-3">*/}
                    {/*        Argon is a great free UI package based on Bootstrap*/}
                    {/*        4 that includes the most important components and*/}
                    {/*        features.*/}
                    {/*      </p>*/}
                    {/*      <div>*/}
                    {/*        <Badge color="success" pill className="mr-1">*/}
                    {/*          business*/}
                    {/*        </Badge>*/}
                    {/*        <Badge color="success" pill className="mr-1">*/}
                    {/*          vision*/}
                    {/*        </Badge>*/}
                    {/*        <Badge color="success" pill className="mr-1">*/}
                    {/*          success*/}
                    {/*        </Badge>*/}
                    {/*      </div>*/}
                    {/*      <Button*/}
                    {/*        className="mt-4"*/}
                    {/*        color="success"*/}
                    {/*        href="#pablo"*/}
                    {/*        onClick={(e) => e.preventDefault()}*/}
                    {/*      >*/}
                    {/*        Learn more*/}
                    {/*      </Button>*/}
                    {/*    </CardBody>*/}
                    {/*  </Card>*/}
                    {/*</Col>*/}
                    {/*<Col lg="4">*/}
                    {/*  <Card className="card-lift--hover shadow border-0">*/}
                    {/*    <CardBody className="py-5">*/}
                    {/*      <div className="icon icon-shape icon-shape-warning rounded-circle mb-4">*/}
                    {/*        <i className="ni ni-planet"/>*/}
                    {/*      </div>*/}
                    {/*      <h6 className="text-warning text-uppercase">*/}
                    {/*        Prepare Launch*/}
                    {/*      </h6>*/}
                    {/*      <p className="description mt-3">*/}
                    {/*        Argon is a great free UI package based on Bootstrap*/}
                    {/*        4 that includes the most important components and*/}
                    {/*        features.*/}
                    {/*      </p>*/}
                    {/*      <div>*/}
                    {/*        <Badge color="warning" pill className="mr-1">*/}
                    {/*          marketing*/}
                    {/*        </Badge>*/}
                    {/*        <Badge color="warning" pill className="mr-1">*/}
                    {/*          product*/}
                    {/*        </Badge>*/}
                    {/*        <Badge color="warning" pill className="mr-1">*/}
                    {/*          launch*/}
                    {/*        </Badge>*/}
                    {/*      </div>*/}
                    {/*      <Button*/}
                    {/*        className="mt-4"*/}
                    {/*        color="warning"*/}
                    {/*        href="#pablo"*/}
                    {/*        onClick={(e) => e.preventDefault()}*/}
                    {/*      >*/}
                    {/*        Learn more*/}
                    {/*      </Button>*/}
                    {/*    </CardBody>*/}
                    {/*  </Card>*/}
                    {/*</Col>*/}
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-lg">
                <Col lg="8">
                  <h2 className="display-3">团队简介</h2>
                  <p className="lead text-muted">
                    绚予堂团队的成员，都是日复一日工作在视光工作一线的专业人士，<br/>
                    大胖老师在视光行业深耕数十年， 热爱与精通视光行业的核心业务。<br/>
                    郭郭老师作为年轻的力量，成为孩子们视训的可靠帮手。<br/>
                    我们的宗旨是在“变”中求存，在实践与反馈中反思并前行。<br/>
                    我们的团队日益革新，不断完善，以期为您提供更好的服务。
                  </p>
                </Col>
              </Row>
              <Row>
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("assets/img/theme/dapang.png")}
                      style={{width: "200px"}}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">大胖老师</span>
                        <small className="h6 text-muted">首席验光师、视光专家</small>
                      </h5>
                    </div>
                  </div>
                </Col>
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("assets/img/theme/guoguo.png")}
                      style={{width: "200px"}}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">郭郭老师</span>
                        <small className="h6 text-muted">
                          首席视训师
                        </small>
                      </h5>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <XuanyutangGamesFooter/>
      </>
    );
  }
}

export default Index;
