import React, {useState, useEffect} from 'react';

import styles from './Schulte.module.css';
import {useTimer} from 'use-timer';
import {Button, Progress, Row} from "reactstrap";

export const Schulte = () => {
  // const initialArr = Array.from({ length: 24 }, (_, i) => i + 1);
  const [counter, setCounter] = useState(1);
  const [progress, setProgress] = useState(1);
  const [misses, setMisses] = useState(0);
  const [isStarted, setIsStarted] = useState(false);
  const [isResults, setIsResults] = useState(false);
  const {time, start, pause, reset} = useTimer();
  const [level, setLevel] = useState("24");
  const [greenAndRed, setGreenAndRed] = useState(false);
  const [table, setTable] = useState([]);


  useEffect(() => {
    if (isResults) {
      setProgress(100);
    } else {
      setProgress(((counter - 1) / parseInt(level) * 100).toFixed(2));
    }
    if (counter === parseInt(level) + 1) {
      endGame();
    }
    if (!isStarted) {
      initTable();
    }
  }, [counter, level]);

  const startGame = () => {
    reset();
    start();
    setIsResults(false);
    setIsStarted(true);
    setMisses(0);
    setProgress(0);
    startTable();
    insertMiddleEl();
  };

  const endGame = () => {
    setIsStarted(false);
    setCounter(1);
    pause();
    setIsResults(true);
  };

  const initTable = () => {
    const initTable = Array.from({length: parseInt(level)}, (_, i) => '');
    initTable.splice(parseInt(level) / 2, 0, <i className="fa fa-dot-circle-o" aria-hidden="true"/>);
    setTable(initTable);
  };

  const startTable = () => {
    const initTable = Array.from({length: parseInt(level)}, (_, i) => i + 1);
    initTable.sort(() => (Math.random() > 0.5 ? 1 : -1));
    initTable.splice(parseInt(level) / 2, 0, <i className="fa fa-dot-circle-o" aria-hidden="true"/>);
    setTable(initTable);
  };

  const insertMiddleEl = () => {
    table.splice(12, 0, '👁');
  };

  const handleClick = (e) => {
    const cube = e.target;
    const attempt = parseInt(cube.innerHTML);
    if (attempt === counter) {
      setCounter(counter + 1);
    } else {
      setMisses(misses + 1);
    }
    if (isStarted && !greenAndRed) {
      const color = cube.style.backgroundColor;
      if (attempt === counter) {
        cube.style.backgroundColor = '#4fd69c'
      } else {
        cube.style.backgroundColor = '#f75676'
      }
      setTimeout(() => {
        cube.style.backgroundColor = color;
      }, 500);
    }
    if (isStarted && greenAndRed) {
      const value = parseInt(cube.innerHTML);
      const index = table.indexOf(value);
      if (attempt === counter) {
          table[index] = <i className="fa fa-circle-o" aria-hidden="true"></i>;
      } else {
          table[index] = <i className="fa fa-times" aria-hidden="true"></i>;
      }
      setTimeout(() => {
        table[index] = value;
      }, 500);
    }

  };


  function getMaxWidth() {
    switch (level) {
      case "24":
        return '30rem';
      case "48":
        return '40rem';
      case "80":
        return '48rem';
      default:
        return '30rem';
    }
  }

  function getCubeSize() {
    switch (level) {
      case "24":
        return '5rem';
      case "48":
        return '5rem';
      case "80":
        return '5rem';
      default:
        return '5rem';
    }
  }

  function getFontSize() {
    switch (level) {
      case "24":
        return '2rem';
      case "48":
        return '1.5rem';
      case "80":
        return '1.5rem';
      default:
        return '2rem';
    }
  }

  function getBackgroundColor(num) {
    if (greenAndRed) {
      if (typeof num === 'number') {
        return num % 2 === 0 ? '#FC3939' : '#76fbf2';
      }
      return "#ced4da";
    }
    return "#ced4da";
  }

  return (
    <>
      <Row className="justify-content-center">
        <Button
          disabled={isStarted}
          color="primary"
          onClick={startGame}
        >
          {isResults ? <span>重新开始?</span> : <span>开始</span>}
        </Button>
        <select id={'level'}
                style={{
                  color: '#fff',
                  backgroundColor: '#5e72e4',
                  borderColor: '#5e72e4',
                  width: '80px',
                  textAlign: 'center',
                  borderRadius: '0.25rem',
                  boxShadow: '0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)'
                }}
                disabled={isStarted}
                value={level}
                onChange={e => {
                  setLevel(e.target.value)
                }}>
          <option value="24">5 X 5</option>
          <option value="48">7 X 7</option>
          <option value="80">9 X 9</option>
        </select>
        <label className="custom-toggle mt-2 ml-2">
          <input type="checkbox"
                 disabled={isStarted}
                 onChange={e => {
                   setGreenAndRed(e.target.checked);
                 }}/>
          <span className="custom-toggle-slider rounded-circle"/>
        </label>
        <label className="mt-2 ml-2">开启红绿</label>
      </Row>
      {!isStarted && (
        <div style={{maxWidth: getMaxWidth()}} className={styles.Table}>
          {table.map((num, index) => {
            return (
              <div onClick={handleClick} key={`${num}/${index}`}>
                <div style={{
                  width: getCubeSize(),
                  height: getCubeSize(),
                  fontSize: getFontSize(),
                  backgroundColor: getBackgroundColor(num)
                }}
                     className={styles.elementAwaited}>{num}</div>
              </div>
            );
          })}
        </div>
      )}

      {isStarted && (
        <div style={{maxWidth: getMaxWidth()}} className={styles.Table}>
          {table.map((num, index) => {
            return (
              <div onClick={handleClick} key={`${num}/${index}`}>
                <div style={{
                  width: getCubeSize(),
                  height: getCubeSize(),
                  fontSize: getFontSize(),
                  backgroundColor: getBackgroundColor(num)
                }}
                     className={greenAndRed ? styles.elementAwaitedRedAndGreen : styles.elementAwaited}>{num}</div>
              </div>
            );
          })}
        </div>
      )}
      <div className="progress-wrapper">
        <div className="progress-info">
          <div className="progress-label">
            <h6>完成度</h6>
          </div>
          <div className="progress-percentage">
            <span>{progress + '%'}</span>
          </div>
        </div>
        <Progress max="100" value={progress}/>
      </div>
      <Row className="justify-content-center">
        {!isResults && (
          <div>
            <span>
                <svg viewBox="0 0 1024 1024"
                     version="1.1"
                     xmlns="http://www.w3.org/2000/svg"
                     width="21.5"
                     height="21.5"
                     className='mb-1'
                >
                <path
                  d="M518.144 946.176c-237.568 0-431.104-193.536-431.104-431.104C87.04 276.48 280.576 82.944 518.144 82.944S949.248 276.48 949.248 515.072c0 237.568-193.536 431.104-431.104 431.104z m0-793.6c-199.68 0-362.496 162.816-362.496 362.496s162.816 362.496 362.496 362.496S880.64 714.752 880.64 515.072c0-200.704-162.816-362.496-362.496-362.496z"
                  fill="#4C4C4C">
                </path>
                <path
                  d="M672.768 566.272H531.456c-35.84 0-65.536-29.696-65.536-65.536V289.792c0-19.456 15.36-34.816 34.816-34.816s34.816 15.36 34.816 34.816v206.848h138.24c19.456 0 34.816 15.36 34.816 34.816-1.024 19.456-16.384 34.816-35.84 34.816z"
                  fill="#4C4C4C">
                </path>
                <path
                  d="M87.04 273.408c-13.312 0-26.624-5.12-36.864-15.36-20.48-20.48-20.48-53.248 0-72.704L153.6 80.896c20.48-20.48 53.248-20.48 72.704 0 20.48 20.48 20.48 53.248 0 72.704L122.88 258.048c-10.24 10.24-22.528 15.36-35.84 15.36zM949.248 273.408c-13.312 0-26.624-5.12-36.864-15.36L808.96 154.624c-20.48-20.48-20.48-53.248 0-72.704 20.48-20.48 53.248-20.48 72.704 0l104.448 102.4c20.48 20.48 20.48 53.248 0 72.704-10.24 11.264-23.552 16.384-36.864 16.384z"
                  fill="#FFA028">
                </path>
            </svg></span>
            <span> {time} 秒 </span>
            <span>错误次数 {misses}</span>
          </div>
        )}
        {isResults && (
          <div>
            <span> 恭喜! </span>
            <span> 时间 {time} 秒 </span>
            <span> 错误次数 {misses} 次</span>
          </div>
        )}
      </Row>
    </>
  );
};
